<template>
  <!--編集モーダル-->
  <div class="modal-wrapping">
        <div class="overlay">
          <form class="modal-wrapping">
            <div class="close-modal" v-on:click="clickEvent"><i class="fa fa-2x fa-times"></i></div>
            <p class="modal-wrapping__title">レビューを投稿します</p>
            <!-- eslint-disable max-len -->
            <div class="checkbox-area">
              <div class="checkbox paper">
                <p class="checkbox-name paper">トイレットペーパー</p>
                <div class="f-switch-box">
                  <span class="f-switch-label">あり</span>
                  <input type="checkbox" id="paper" name="sparty_quiz[troubles][trouble3]" v-on:click ="checkRadioPaper">
                  <label type="checkbox" for="paper" class="f-switch"></label>
                  <span class="f-switch-label">なし</span>
                </div>
              </div>
              <div class="checkbox pay">
                <p class="checkbox-name pay">使用料</p>
                <div class="f-switch-box">
                  <span class="f-switch-label">有料</span>
                  <input type="checkbox" id="pay" name="sparty_quiz[troubles][trouble3]" v-on:click ="checkRadioPay">
                  <label type="checkbox" for="pay" class="f-switch"></label>
                  <span class="f-switch-label">無料</span>
                </div>
              </div>
            </div>
            <div class="rate cleanliness">
              <p>清潔度</p>
              <star-rating
              v-on:rating-selected ="setCleanlinessRating"
              v-bind:increment="0.5"
              inactive-color="#e8e6e6"
              active-color="#98d3ed"
              border-color="#d8d8d8"
              v-bind:star-size="30"
              v-bind:star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
              v-bind:rounded-corners="true"
              v-bind:show-rating="false"
              ></star-rating>
            </div>
            <div class="rate rare">
              <p>レア度</p>
              <star-rating
              v-on:rating-selected ="setRareRating"
              v-bind:increment="0.5"
              active-color="#98d3ed"
              inactive-color="#e8e6e6"
              border-color="#40210f"
              v-bind:star-size="30"
              v-bind:star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
              v-bind:rounded-corners="true"
              v-bind:show-rating="false"
              ></star-rating>
            </div>
            <div class="modal-wrapping__name">画像</div>
            <input
            type="file"
            accept=".png, .jpg, .jpeg"
            class="modal-wrapping__formcontrol addimg"
            v-on:change="selectedFile"
            >
            <div class="pic-image"></div>
            <div class="modal-wrapping__name">訪れた日</div>
            <input
            type="date"
            class="modal-wrapping__formcontrol visitdate"
            v-model="visitDate"
            >
            <div class="modal-wrapping__name">コメント</div>
            <textarea
            wrap="soft"
            cols="25"
            class="modal-wrapping__textarea comment"
            v-model="comment"
            ></textarea>
            <div
            type="submit"
            class="modal-wrapping__submit"
            v-on:click="registEditdata"
            >
              投稿
            </div>
          </form>
        </div>
      </div>
</template>

<script>
import firebase from 'firebase';
import StarRating from 'vue-star-rating';

const geofire = require('geofire-common');

export default {
  props: ['lat', 'lng' ],
  components: {
    StarRating,
  },
  data() {
    return {
      // フォーム
      visitDate: '',
      image: '',
      paper: 'なし',
      pay: '無料',
      comment: '',
      newcomment: '',
      cleanlinessRating: 0,
      rareRating: 0,
      // 画像保存
      uploadFile: null,
      fileName: '',
      stringUrl: null,
    };
  },
  mounted() {
    // user id取得
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.currentUid = user.uid;
        console.log(this.currentUid);
      }
    });
  },
  methods: {
    setCleanlinessRating(rating) {
      this.cleanlinessRating = rating;
      console.log(this.cleanlinessRating);
    },
    setRareRating(rating) {
      this.rareRating = rating;
      console.log(this.rareRating);
    },
    // closeボタンイベント発行
    clickEvent() {
      this.$emit('click-closebutton');
    },
    // トイレットペーパー有無チェック
    checkRadioPaper() {
      if (document.getElementById('paper').checked) {
        this.paper = 'あり';
      } else {
        this.paper = 'なし';
      }
      console.log('トイレットペーパー', this.paper);
    },
    // 使用料有料or無料チェック
    checkRadioPay() {
      if (document.getElementById('pay').checked) {
        this.pay = '有料';
      } else {
        this.pay = '無料';
      }
      console.log('使用料', this.pay);
    },
    // 画像セレクト
    selectedFile(e) {
      e.preventDefault();
      // const files = e.target.files;
      const { files } = e.target;
      // this.uploadFile = files[0];
      [this.uploadFile] = files;
      this.fileName = this.uploadFile.name;
      console.log(e);
    },
    // firestore登録(set)
    registEditdata() {
      const addInfo = () => {
        // definedを無視する
        // 設定を再読み込みしている箇所が2回以上呼ばれるとエラーとなるので未定義の場合のみ
        if(firebase == null) {
        firebase
          .firestore()
          .settings({ ignoreUndefinedProperties: true });
        }
        // コメントの改行を表示させる
        this.comment = this.comment.replace(/&/g, "&amp;");
        this.comment = this.comment.replace(/</g, "&lt;");
        this.comment = this.comment.replace(/>/g, "&gt;");
        this.newcomment = this.comment.replace(/\n/g, '\n');
        // firestoreのデータを更新する
        // const date = new Date();
        firebase
          .firestore()
          .collection(`${this.lat}${this.lng}`)
          .doc(`${this.currentUid}`)
          .set({
            CurrentId: this.currentUid, // detailpageのv-bind:keyに設定
            CreateAt: firebase.firestore.FieldValue.serverTimestamp(),
            // registerDate: date.getFullYear() + '年' + date.getMonth()+1 + '月' + date.getDate() + '日',
            ImageUrl: this.stringUrl,
            Visitdate: this.visitDate,
            Comment: this.newcomment,
            Paper: this.paper,
            Pay: this.pay,
            CleanlinessRating: this.cleanlinessRating,
            RareRating: this.rareRating,
          });
          // カスタムイベントを発行する
        　this.$emit('click-editbutton');
　        　this.$router.push({
              name: 'detailPage',
              params: {
                  id: `${this.lat}${this.lng}`
            }
          });

      };
      const pushMapMaker = () => {
        firebase
        .firestore()
        .collection('restroomMap')
        .doc(`${this.lat}${this.lng}`)
        .update({
          CurrentId: firebase.firestore.FieldValue.arrayUnion(`${this.currentUid}`),
        });
      };
     // firetore更新条件分岐
     if (this.fileName.length !== 0) {
        // 画像ファイルアップ
        firebase
          .storage()
          .ref(`restroom-images/${this.fileName}`)
          .put(this.uploadFile)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              this.stringUrl = url;
              console.log(this.stringUrl);
              // データベース登録
              addInfo();
              pushMapMaker();
              console.log('画像ありでアップロード');
            });
          })
          .catch((error) => {
            console.error('アップロード失敗', error);
          });
      } else {
        // 画像なしでデータベース登録
        addInfo();
        pushMapMaker();
        console.log('画像なしでアップロード');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay .modal-wrapping {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  width: 450px;
  height: 500px;
  background-color: #fafafa;
  border-radius: 1.5rem;
  text-align: center;
  padding: 3rem 4rem;
  overflow-y: scroll;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping {
    width: 73%;
    top: 5%;
    padding: 3rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__title {
  font-weight: 700;
  margin-bottom: 4.3rem;
  letter-spacing: .3rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__title {
    font-weight: 700;
    font-size: 1.5rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__name {
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #40210f;
  text-align: left;
  letter-spacing: 0.2rem;
}

.overlay .modal-wrapping .modal-wrapping__formcontrol {
  /* デフォルトスタイルをリセット */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* font-familyを継承しないので、継承させる */
  font-family: inherit;
  background-color: transparent;
  background-image: none;
  border: 1px solid rgba(64, 33, 15, 0.7);
  border-radius: .4rem;
  color: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 100%;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: .4rem 0 1rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__formcontrol {
    font-size: 0.8rem;
    height: 3rem;
  }
}

.modal-wrapping__textarea.comment {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* font-familyを継承しないので、継承させる */
  font-family: inherit;
  background-color: transparent;
  background-image: none;
  border: 1px solid rgba(64, 33, 15, 0.7);
  border-radius: .4rem;
  color: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 100%;
  height: 9rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: .4rem 0 1rem;
}

.overlay .modal-wrapping .modal-wrapping__submit {
  border-radius: 2.5rem;
  background-color: #75ccf3;
  border: solid 2px rgba(64, 33, 15, 0.8);
  color: #40210f;
  width: 100%;
  font-weight: bold;
  font-size: 1.6rem;
  transition: .3s;
  cursor: default;
  margin: 4rem auto 2rem;
  line-height: 3.8rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__submit {
    line-height: 3.1rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__submit:hover {
  background-color: #94d8f7;
}

.overlay .modal-wrapping .modal-wrapping__submit:disabled {
  color: rgba(64, 33, 15, 0.5);
  background-color: #bce3f5;
  border: solid 2px rgba(64, 33, 15, 0.5);
}

.overlay .modal-wrapping .close-modal {
  text-align: right;
  font-size: 1.5rem;
  cursor: pointer;
  color: #5c2f15;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .close-modal {
    font-size: 1.6rem;
  }
}

.overlay .modal-wrapping .modal-msgbox {
  height: 2rem;
  margin-bottom: 1rem;
}

.overlay .modal-wrapping .modal-msgbox .modal-errormsg {
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.1rem;
  color: red;
}

/*マップ*/
#mapPreview {
  margin: 3rem auto 2rem;
  height: 20rem;
  border: solid .05rem rgba(64, 33, 15, 0.7);
  width: 92%;
}

/*チェックボックス*/
@media (max-width: 670px) {
  .checkbox-area {
      margin: 3rem auto 1rem;
  }
}

.checkbox {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 670px) {
  .checkbox {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 670px) {
  .checkbox-name {
    margin: 0;
  }
}

.f-switch-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.f-switch {
    width: 8rem;
    height: 3rem;
    position: relative;
    display: inline-block;
    border-radius: 1.5rem;
    transition: 0.4s;
    box-sizing: border-box;
    background-color: #e8e6e6;
    margin: 0 2rem;
    /*border: solid .18rem rgba(64, 33, 15, 0.7) ;*/
}

@media (max-width: 670px) {
  .f-switch {
    margin: 0.5rem;
  }
}

.f-switch-box > input:checked + .f-switch {
    background: #d1e6f0;
    /*border: solid .05rem rgba(64, 33, 15, 0.7) ;*/
}

.f-switch:after {
    content: '';
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
    right: 0;
    top: -.1rem;
    z-index: 2;
    background: #98d3ed;
    box-shadow: 0 0 .3rem rgba(64, 33, 15, 0.5);
    transition: 0.4s;
}

.f-switch-box > input:checked + .f-switch:after {
    right: 5rem;
}

.f-switch-box > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.f-switch-label {
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

/*レート*/
.rate {
  display: flex;
  justify-content: space-between;
}

.rare {
  margin-bottom: 2rem;
}

</style>
