<template>
  <!--フッター-->
  <section id="footer">
    <div id="footercontent" class='comtainer'>
      <nav class="footer-nav">
        <a href="">利用規約</a>
        <a href="">ガイドライン</a>
        <a href="" target="_blank">お問い合わせ</a>
        <a href="https://twitter.com/MapRestroom" target="_blank">公式Twitter</a>
      </nav>
      <small>© the world restroom map,All Rights Reserved.</small>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase/app';
import AddModal from '@/components/AddModal.vue'

export default {
  data() {
    return {
      
    };
  },
  methods: {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

small {
  background-color: #dcf0f7;
  display: block;
  text-align: center;
  padding: 1.3rem 0 0.5rem;
}

.footer-nav {
  display: block;
  text-align: center;
  padding: 1rem 0;
}

.footer-nav a{
  text-decoration: none;
  color: #40210f;
  font-size: 1.2rem;
  padding: 0 .8rem;
}

.footer-nav {
  position: relative;
}

.footer-nav::after {
  content: '';
  position: absolute;
  right: 0;
  left: -0%;
  top: 100%;
  z-index: 10;
  display: block;
  height: 20px;
  background-size: 20px 100%;
  background-image: linear-gradient(135deg, #fafafa 25%, transparent 25%), linear-gradient(225deg, #fafafa 25%, transparent 25%);
  background-position: 0 0;
}
</style>
