<template>
  <!--トップページ-->
  <section id="userheader">
    <div id="userheadercontent" class='comtainer'>
      <div class="user-messeage">
        <p
        class="username"
        >
          {{ currentUname }}さん、ようこそ
        </p>
        <p
          class="logout"
          v-on:click="logOut"
          >
            ログアウト
        </p>
      </div>
      <h1><a href="index.html"><img src="/img/header-title.png" alt="世界のトイレマップ" class="userheader-title"></a></h1>
      <div class="add-button">
        <div
        class="btn-basic button__add"
        v-on:click="openAddModal"
        >
          トイレ情報を投稿する<br>
          <span><i class="fas fa-plus-circle"></i></span>
        </div>
      </div>
    </div>
    <!--編集モーダル-->
    <add-modal
    v-if="addModalShow"
    v-on:click-closebutton="closeAddModal"
    v-on:set-latlng="setLatlng"
    v-on:click-addbutton="changeWindow">
    </add-modal>
  </section>
</template>

<script>
import firebase from 'firebase/app';
import AddModal from '@/components/AddModal.vue'

export default {
  components: {
    AddModal
  },
  props: ['currentUname'],
  data() {
    return {
      // 表示関連
      addModalShow: false,
      mapCanvasShow: false,
      // 地図
      spot: '',
      map: {},
      marker: null,
      geocoder: {},
      lat: {},
      lng: {},
      latlng: {},
      markerlng: {},
      markerlat: {},
      markerData: [],
    };
  },
  methods: {
    openAddModal() {
      this.mapCanvasShow = false;
      this.addModalShow = true;
    },
    closeAddModal() {
      this.addModalShow = false;
    },
    changeWindow() {
      this.addModalShow = false;
      this.$emit('change-window');
      // this.detailPageOpen = true;
    },
    // ログアウト
    logOut() {
      firebase.auth().signOut();
      document.location.reload();
    },
    setLatlng(lat, lng) {
      this.lat = lat;
      this.lng = lng;
      this.$emit('set-latlng', this.lat, this.lng);
      console.log(this.lat);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.userheader-title {
  width: 40%;
  padding-top: 1rem;
}

@media (max-width: 670px) {
  .userheader-title {
    width: 80%;
    margin: 2.5rem auto;
  }
}

#userheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/img/bg-img.png);
  background-color: #dcf0f7;
  background-size: contain;
  background-repeat: repeat;
  text-align: center;
  padding: 4rem 0;
  background-color: #dcf0f7;
  width: 100%;
}

.add-button {
  padding: 1rem 3rem;
  margin: 6rem auto 3rem;
  border-radius: 10.5rem;
  background-color: #75ccf3;
  border: solid 0.35rem #40210f;
  color: #40210f;
  width: 33rem;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.8rem;
  transition: .3s;
  cursor: pointer;
}

@media (max-width: 670px) {
  .add-button {
    margin: 1rem auto 3rem;
    width: 16rem;
    font-size: 1.3rem;
    line-height: 2.3rem;
    border: solid 0.3rem #40210f;
  }
}

.add-button span {
  font-size: 2.2rem;
}

.add-button:hover {
  background-color: #81bf24;
}

.user-messeage {
  display: flex;
  justify-content: center;
}

@media (max-width: 670px) {
  .user-messeage {
   display: flex;
   flex-direction: column;
 }
}

.user-messeage .logout {
  cursor: pointer;
  margin-left: 2rem;
}

@media (max-width: 670px) {
  .user-messeage .logout {
    margin: .4rem auto 0;
  }
}

.user-messeage .username {
  font-weight: 700;
  cursor: pointer;
}

@media (max-width: 670px) {
  .user-messeage .username {
    margin: 0;
  }
}

@media (max-width: 670px) {
  .userheadersub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 670px) {
  #userheadercontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

</style>
