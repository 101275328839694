<template>
    <div class="modal-wrapping" id="modal-login">
        <div class="overlay">
            <form class="modal-wrapping">
                <div class="close-modal" v-on:click="clickEvent">
                    <i class="fa fa-2x fa-times"></i>
                </div>
                <p class="modal-wrapping__title">パスワード再設定</p>
                <div class="modal-wrapping__name">メールアドレス</div>
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                <input
                type="email"
                class="modal-wrapping__formcontrol login-email"
                v-model="registerEmail"
                v-on:keyup="checkEmail"
                >
                <div class="modal-msgbox">
                    <p class="modal-errormsg" v-show="errormsgInvalid">無効なメールアドレスです</p>
                </div>
                <button
                type="submit"
                class="modal-wrapping__submit"
                v-bind:disabled="addDisabled"
                v-on:click="clickSubmitButton"
                >
                 メールを送信する
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import v8n from 'v8n';
import firebase from 'firebase';

export default {
  data() {
    return {
      registerEmail: '',
      errormsg: false,
      errormsgInvalid: false,
      addDisabled: true,
    };
  },
  methods: {
  // 閉じるボタンイベント受け渡し
    clickEvent() {
      this.$emit('click-close');
    },

    // バリデーション
    checkEmail() {
      const check = v8n()
        .not.null()
        .string() // 文字列
        .minLength(5) // a@b.c を想定して最低5文字
        .pattern(/[^\s@]+@[^\s@]+\.[^\s@]+/) // eメール用の正規表現
        .test(this.registerEmail); // 検証
      console.log(check);
      if (!check) {
        this.errormsgInvalid = true;
      } else {
        this.errormsgInvalid = false;
      }
      this.toggledisabled();
    },
    toggledisabled() {
      if (this.registerEmail.length !== 0 ) {
        // console.log('ここまで動いてます！');
        if (!this.errormsgInvalid) {
          // console.log('さらにここまでも動いてます！')
          this.addDisabled = false;
        }
      }
      if (this.registerEmail.length === 0 || this.errormsgInvalid) {
        this.addDisabled = true;
      }
    },
    clickSubmitButton(e) {
      e.preventDefault();
      firebase.auth().sendPasswordResetEmail(this.registerEmail)
      .then(() => {
      // Password reset email sent.
      console.log('成功')
      })
      .catch((error) => {
      // Error occurred. Inspect error.code.
      })
    },
    // // ログイン
    // logIn(email, pass) {
    //   firebase.auth()
    //     .signInWithEmailAndPassword(email, pass)
    //     .then((user) => {
    //       console.log('ログインしました', user);
    //     })
    //     .catch((error) => {
    //       console.error('ログインエラー', error);
    //       this.errormsg = true;
    //     });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay .modal-wrapping {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  width: 450px;
  background-color: #fafafa;
  border-radius: .5rem;
  text-align: center;
  padding: 2rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping {
    width: 90%;
    top: 5%;
    padding: 1rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__title {
  font-weight: 700;
  margin-bottom: 4.3rem;
  font-size: 2rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__title {
    font-weight: 700;
    font-size: 1.7rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__name {
  font-size: 1.5rem;
  line-height: 1.2rem;
  color: #40210f;
  text-align: left;
  letter-spacing: 0.2rem;
}

.overlay .modal-wrapping .modal-wrapping__formcontrol {
  /* デフォルトスタイルをリセット */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* font-familyを継承しないので、継承させる */
  font-family: inherit;
  background-color: transparent;
  background-image: none;
  border: 1px solid rgba(64, 33, 15, 0.7);
  border-radius: .4rem;
  color: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 100%;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: .4rem 0 1rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__formcontrol {
    font-size: 0.8rem;
    height: 3rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__submit {
  border-radius: 2.5rem;
  background-color: #75ccf3;
  border: solid 2px rgba(64, 33, 15, 0.8);
  color: #40210f;
  width: 100%;
  font-weight: bold;
  font-size: 1.6rem;
  transition: .3s;
  cursor: default;
  margin: 4rem auto 2rem;
  line-height: 3.8rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__submit {
    line-height: 3.1rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__submit:hover {
  background-color: #94d8f7;
}

.overlay .modal-wrapping .modal-wrapping__submit:disabled {
  color: rgba(64, 33, 15, 0.5);
  background-color: #bce3f5;
  border: solid 2px rgba(64, 33, 15, 0.5);
}

.overlay .modal-wrapping .close-modal {
  text-align: right;
  font-size: 2rem;
  cursor: pointer;
  color: #5c2f15;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .close-modal {
    font-size: 1.6rem;
  }
}

.overlay .modal-wrapping .modal-msgbox {
  height: 2rem;
  margin-bottom: 1rem;
}

.overlay .modal-wrapping .modal-msgbox .modal-errormsg {
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.1rem;
  color: red;
}

</style>
