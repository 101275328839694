<template>
  <!--トップページ-->
  <section id="passwardResetWindow">
    <div id="headercontent" class="container">
      <h1><a href="index.html"><img src="img/header-title.png" alt="世界のトイレマップ" class="header-title"></a></h1>
      <form class="window-wrapping">
        <p class="window-wrapping__title">パスワード再設定</p>
        <div class="window-wrapping__name">パスワード</div>
        <!-- eslint-disable-next-line vue/max-attributes-per-line -->
        　　<input type="password" class="window-wrapping__formcontrol reset-password" placeholder="6文字以上" v-model="resetPassword" v-on:keyup="checkPassword">
        <div class="window-msgbox">
          <p class="window-errormsg" v-if="errormsgPass">パスワードは6文字以上にしてください</p>
        </div>
        <div class="window-wrapping__name">パスワード(確認)</div>
        <input type="password" class="window-wrapping__formcontrol reset-password" placeholder="6文字以上" v-model="resetPasswordConfirm" v-on:keyup="checkPasswordConfirm">
        <div class="window-msgbox">
          <p class="window-errormsg" v-if="errormsgConfirmPass">パスワードが一致しません</p>
        </div>
        <button type="submit" class="window-wrapping__submit" v-bind:disabled="addDisabled" v-on:click="clickReset">
          再設定する
        </button>
      </form>
    </div>
  </section>
</template>

<script>
  import v8n from 'v8n';
  import firebase from 'firebase';

  export default {
    data() {
      return {
        resetPassword: '',
        resetPasswordConfirm: '',
        errormsgPass: false,
        errormsgConfirmPass: false,
        addDisabled: true,
      };
    },
    methods: {
      // バリデーション
      checkPassword() {
        const check = v8n()
          .not.null()
          .string() // 文字列
          .minLength(6)
          .test(this.resetPassword); // 検証
        console.log(check);
        if (!check) {
          this.errormsgPass = true;
        }
        else {
          this.errormsgPass = false;
        }
        this.toggledisabled();
      },
      checkPasswordConfirm() {
        if (this.resetPassword !== this.resetPasswordConfirm) {
          this.errormsgConfirmPass = true;
        }
        else {
          this.errormsgConfirmPass = false;
        }
        this.toggledisabled();
      },
      toggledisabled() {
        if (this.resetPassword.length >= 6 && this.resetPasswordConfirm.length >= 6 && !this.errormsgConfirmPass && !this.errormsgPass) {
          this.addDisabled = false;
        }
        else {
          this.addDisabled = true;
        }
      },
      clickReset(e) {
        e.preventDefault();

        const searchParams = new URLSearchParams(window.location.search);

        var mode = searchParams.get('mode');
        // Get the one-time code from the query parameter.
        var actionCode = searchParams.get('oobCode');
        // (Optional) Get the continue URL from the query parameter if available.
        var continueUrl = searchParams.get('continueUrl');
        // (Optional) Get the language code if available.
        var lang = searchParams.get('lang') || 'en';

        firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
          var accountEmail = email;

          // TODO: Show the reset screen with the user's email and ask the user for
          // the new password.
          var newPassword = this.resetPasswordConfirm;

          // Save the new password.
          firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
            console.log('成功');

          }).catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            console.log('失敗');

          });
        }).catch((error) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
        });

        // firebase.auth().signInWithEmailLink(email, emailLink)
        // .catch(function(error) {
        //   // Some error occurred, you can inspect the code: error.code
        //   // Common errors could be invalid email and invalid or expired OTPs.
        // });

        // const user = firebase.auth().currentUser;
        // const newPassword = this.resetPasswordConfirm;

        // user.updatePassword(newPassword).then(() => {
        //   // Update successful.
        //   console.log('成功');
        // }).catch((error) => {
        //   // An error ocurred
        //   console.log('失敗');
        //   // ...
        // });
      },
      // // ログイン
      // logIn(email, pass) {
      //   firebase.auth()
      //     .signInWithEmailAndPassword(email, pass)
      //     .then((user) => {
      //       console.log('ログインしました', user);
      //     })
      //     .catch((error) => {
      //       console.error('ログインエラー', error);
      //       this.errormsg = true;
      //     });
      // },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*#passwardResetWindow {*/
  /*  background-image: url(/img/bg-img.png);*/
  /*  background-color: #dcf0f7;*/
  /*  background-size: contain;*/
  /*  background-repeat: repeat;*/
  /*height: 100vh;*/
  /*}*/

  #headercontent {
    text-align: center;
    padding-top: 2rem;
  }

  @media (max-width: 670px) {
    #headercontent {
      padding-top: 2rem;
    }
  }

  .header-title {
    width: 33%;
  }

  @media (max-width: 670px) {
    .header-title {
      width: 64%;
    }
  }

  .window-wrapping {
    margin: 5rem auto 0;
    width: 300px;
    /*background-color: #fafafa;*/
    border-radius: .5rem;
    text-align: center;
  }

  @media (max-width: 670px) {
    .window-wrapping {
      width: 90%;
      padding: 1rem;
    }
  }

  .window-wrapping__title {
    font-weight: 700;
    margin-bottom: 3rem;
    font-size: 1.8rem;
  }

  @media (max-width: 670px) {
    .window-wrapping__title {
      font-weight: 700;
      font-size: 1.7rem;
    }
  }

  .window-wrapping__name {
    font-size: 1.5rem;
    line-height: 1.2rem;
    color: #40210f;
    text-align: left;
    letter-spacing: 0.2rem;
    margin-top: 2.5rem;
  }

  .window-wrapping__formcontrol {
    /* デフォルトスタイルをリセット */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /* font-familyを継承させる */
    font-family: inherit;
    background-color: transparent;
    background-image: none;
    border: 1px solid rgba(64, 33, 15, 0.7);
    border-radius: .4rem;
    color: inherit;
    font-size: 1em;
    padding: 0.4em 0.8em;
    width: 100%;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0.8rem 0 0;
  }

  @media (max-width: 670px) {
    .window-wrapping__formcontrol {
      font-size: 0.8rem;
      height: 3rem;
    }
  }

  .window-wrapping__submit {
    border-radius: 2.5rem;
    background-color: #75ccf3;
    border: solid 2px rgba(64, 33, 15, 0.8);
    color: #40210f;
    width: 100%;
    font-weight: bold;
    font-size: 1.6rem;
    transition: .3s;
    cursor: default;
    margin: 3rem auto 4rem;
    line-height: 3.8rem;
  }

  @media (max-width: 670px) {
    .window-wrapping__submit {
      line-height: 3.1rem;
    }
  }

  .window-wrapping__submit:hover {
    background-color: #94d8f7;
  }

  .window-wrapping__submit:disabled {
    color: rgba(64, 33, 15, 0.5);
    background-color: #bce3f5;
    border: solid 2px rgba(64, 33, 15, 0.5);
  }

  .window-wrapping .modal-msgbox {
    height: 2rem;
    margin-bottom: 1rem;
  }

  .window-wrapping .window-msgbox .window-errormsg {
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.1rem;
    color: red;
  }
</style>
