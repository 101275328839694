/* global gtag */
import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';
// import gtag from 'vue-gtag';

import TopPage from './components/TopPage.vue';
import ResetPassWindow from './components/ResetPassWindow.vue';
import DetailPage from './components/DetailPage.vue';
import LoginModal from './components/LoginModal.vue';
import SignupModal from './components/SignupModal.vue';
import ReviewModal from './components/ReviewModal.vue';
import AddModal from './components/AddModal.vue';
import EditModal from './components/EditModal.vue';

Vue.use(VueRouter);

// ナビゲーションガード
const guard = (to, from, next) =>{
  　  firebase.auth().onAuthStateChanged((user) =>{
    　  if (user) {
          next();
      } else {
        next("/");
      }
  　 });
  };

const routes = [
  { path: '/', name: 'top', component: TopPage },
  { path: '/reset', name: 'reset', component: ResetPassWindow },
  { path: '/detail/:registerlat/:registerlng' , name: 'detailPage', component: DetailPage, props: true  },
  { path: '/login' , name: 'login', component: LoginModal },
  { path: '/singup' , name: 'singup', component: SignupModal },
  { path: '/add',
  　name: 'add',
  　component: AddModal,
  　beforeEnter: guard,
  },
  { path: '/add-review' ,
    name: 'add-review', 
    component: ReviewModal,
    beforeEnter: guard,
  },
  { path: '/edit' , name: 'edit', component: EditModal },
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.afterEach((to, from) => {
  // sendToAnalytics(to.fullPath)
  gtag('event', 'page_view', {
    page_path	: to.path,
    page_title: to.name,
  });
})

export default router;

