<template>
  <div id="topPage">
    <NonUserTop
    v-if="nonuser && !isResetPage()"
    v-on:set-uname="setUserName"
    >
    </NonUserTop>
    <UserTop
    v-if="user && !isResetPage()"
    v-bind:currentUname = "currentUname"
    >
    </UserTop>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import NonUserTop from '@/components/NonUserTop.vue';
import UserTop from '@/components/UserTop.vue';


export default {
  components: {
    NonUserTop,
    UserTop,
  },
  data() {
    return {
      // 表示関連
      nonuser: true,
      user: false,
      currentUid: '',
      currentUname: '',
    };
  },
  // beforeUpdate() {
  //   const user = firebase.auth().currentUser.reload();
  //   if (user !== null) {
  //     // The user object has basic properties such as display name, email, etc.
  //     // const displayName = user.displayName;
  //     // The user's ID, unique to the Firebase project. Do NOT use
  //     // this value to authenticate with your backend server, if
  //     // you have one. Use User.getIdToken() instead.
  //     // const uid = user.uid;
  //     this.currentUname = user.displayName;
  //     console.log("firebasae取得",this.currentUname);
  //   }
  // },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('login', user);
        this.currentUid = user.uid;
        this.nonuser = false;
        this.user = true;
        // 通常のログイン時
        console.log('user.providerData', user.providerData[0].displayName);
        if (user.displayName !== null) {
          this.currentUname = user.displayName;
        }
        console.log(this.currentUname);
        // // 全てのデータ
      } else {
        console.log('logout');
        this.nonuser = true;
        this.user = false;
      }
    });
  },
  methods: {
    setUserName(username) {
      this.currentUname = username;
    },
    isResetPage () {
      return this.$route.path === '/reset';
    }
  },
};
</script>

<style>

</style>
