<template>
  <!--マップエリア-->
  <section id="searchmapArea" class='container'>
    <p class="search-title">トイレどこ？</p>
    <div class="search-map__form">
      <input
      type="text"
      placeholder="キーワードから探す"
      class="search-map__formcontrol"
      v-model="spot"
      >
      <button
      type="submit"
      v-on:click ="mapSearch"
      >
        <i class="fas fa-search"></i>
      </button>
    </div>
    <p
    id="search-currentlocation"
    v-on:click ="mapCurrentPosition"
    >
      現在位置から探す
    </p>
    <div class="map-area"
    v-show="mapCanvasShow">
      <p v-if="markerData.length !== 0">3km圏内に{{ markerData.length }}件あります。</p>
      <p v-else>3km圏内に投稿されたトイレ情報はありません。</p>
      <div id="mapCanvas"></div>
      <div class="marker-description">
        <img src="./img/marker-icon.png" alt="トイレマーク">
        <p>をクリックして詳細ページへ</p>
      </div>
  　</div>
  </section>
</template>

<script>
import firebase from 'firebase';
// vueで画像指定方法はimport
import markerImage from '@/assets/marker-icon.png';

const geofire = require('geofire-common');

export default {
  data() {
    return {
      // 表示関連
      mapCanvasShow: false,
      noResultMesseage: false,
      // 地図
      spot: '',
      map: {},
      marker: null,
      geocoder: {},
      lat: {},
      lng: {},
      latlng: {},
      markerlng: {},
      markerlat: {},
      markerData: [],
    };
  },
  async mounted() {
    // document.addEventListener("touchmove", this.mapSearch, {passive: true});
    // document.addEventListener("touchstart", this.mapSearch, {passive: true});
    // document.addEventListener("touchmove", this.mapCurrentPosition, {passive: true});
    // document.addEventListener("touchstart", this.mapCurrentPosition, {passive: true});
    await this.waitGmap();
    this.map = new window.google.maps.Map(document.getElementById('mapCanvas'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 12,
      disableDefaultUI: true,
    });
    this.geocoder = new window.google.maps.Geocoder();
  },
  methods: {
    // 表示関連
    closeMaparea() {
      this.mapCanvasShow = false;
    },
    // googleMap読み込み
    waitGmap() {
      return new Promise((resolve, reject) => {
        let retry = 0;
        const id = setInterval(() => {
          if (window.gmaploaded === true) {
            resolve();
            clearInterval(id);
          } else {
            retry += 1;
            if (retry === 10) {
              reject();
              clearInterval(id);
            }
          }
        }, 100);
      });
    },
    // 場所から地図を検索する
    mapSearch(e) {
      e.preventDefault();
      this.geocoder.geocode({
        address: this.spot,
      }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.map.setCenter(results[0].geometry.location);
          // 緯度経度の取得
          this.lat = results[0].geometry.location.lat();
          this.lng = results[0].geometry.location.lng();
          console.log(this.lat);
          this.latlng = new window.google.maps.LatLng(this.lat, this.lng);
          this.marker = new window.google.maps.Marker({
            map: this.map,
            position: this.latlng,
          });
          // 3km圏内にあるトイレ情報を取得
          const center = [this.lat, this.lng];
          const radiusInM = 3 * 1000;
          const bounds = geofire.geohashQueryBounds(center, radiusInM);
          const promises = [];
          for (const b of bounds) {
            const q = firebase.firestore().collection('restroomMap')
              .orderBy('Geohash')
              .startAt(b[0])
              .endAt(b[1]);
            promises.push(q.get());
            console.log(promises);
          }
          Promise.all(promises).then((snapshots) => {
             console.log(this.lat);
            console.log('a');
            const array = [];
            console.log('b');
            for (const snap of snapshots) {
              console.log('c');
              console.log(this.markerData.length);
              // if(this.markerData.length !== 0) {
                this.mapCanvasShow = true;
              // } else {
                // this.noResultMesseage = true;
              // }
              for (const doc of snap.docs) {
                console.log('d');
                const dataLat = doc.get('Lat');
                console.log(dataLat);
                const dataLng = doc.get('Lng');
                const distanceInKm = geofire.distanceBetween([dataLat, dataLng], center);
                const distanceInM = distanceInKm * 1000;
                console.log(distanceInM);
                if (distanceInM <= radiusInM) {
                  array.push(doc);
                  this.markerData = array;
                  console.log(this.markerData);
                  // マーカー毎の処理
                  // eslint-disable-next-line max-len
                  this.markerLatLng = new window.google.maps.LatLng({ lat: dataLat, lng: dataLng }); // 緯度経度のデータ作成
                  this.marker = new window.google.maps.Marker({ // マーカーの追加
                    position: this.markerLatLng, // マーカーを立てる位置を指定
                    map: this.map, // マーカーを立てる地図を指定
                    disableDefaultUI: true, // デフォルトのUIを無効に
                    icon: markerImage, // import
                  });
                  // クリックしたら遷移するイベント
                  window.google.maps.event.addListener(this.marker, 'click', () => {
                    this.markerLat = dataLat;
                    this.markerLng = dataLng;
                    console.log('markerLat=', this.markerLat);
                    console.log('markerLng=', this.markerLng);
                    // カスタムイベントを発行
                    this.$emit('from-child', this.markerLat, this.markerLng);
                    this.mapCanvasShow = true;
                    // パラメータ渡す
                    // this.$router.push({
                    //   name: 'detailPage',
                    //   params: {
                    //       // id: `${this.markerLat}${this.markerLng}`
                    //       registerlat: this.markerLat,
                    //       registerlng: this.markerLng,
                    //   }
                    // });
                  });
                } 
              }
            }
          });
        } else {
          console.log('失敗');
        }
      });
    },
    // 位置情報から地図を表示する
    mapCurrentPosition(e) {
      e.preventDefault();
      this.mapCanvasShow = true;
      const vm = this; // コールバック関数ではthisが使えないため
      const success = (pos) => {
        vm.lat = pos.coords.latitude;
        vm.lng = pos.coords.longitude;
        vm.latlng = new window.google.maps.LatLng(vm.lat, vm.lng); // 中心の緯度, 経度
        vm.map = new window.google.maps.Map(document.getElementById('mapCanvas'), {
          center: vm.latlng,
          zoom: 17,
          disableDefaultUI: true,
        });
        vm.marker = new window.google.maps.Marker({
          position: vm.latlng, // マーカーの位置（必須）
          map: vm.map, // マーカーを表示する地図
        });
        // 3km圏内にあるトイレ情報を取得
        const center = [vm.lat, vm.lng];
        const radiusInM = 3 * 1000;
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
          const q = firebase.firestore().collection('restroomMap')
            .orderBy('Geohash')
            .startAt(b[0])
            .endAt(b[1]);
          promises.push(q.get());
          console.log(promises);
        }
        Promise.all(promises).then((snapshots) => {
          console.log('a');
          const array = [];
          console.log('b');
          for (const snap of snapshots) {
            console.log('c');
            vm.mapCanvasShow = true;
            for (const doc of snap.docs) {
              console.log('d');
              const dataLat = doc.get('Lat');
              console.log(dataLat);
              const dataLng = doc.get('Lng');
              const distanceInKm = geofire.distanceBetween([dataLat, dataLng], center);
              const distanceInM = distanceInKm * 1000;
              console.log(distanceInM);
              if (distanceInM <= radiusInM) {
                array.push(doc);
                vm.markerData = array;
                console.log(vm.markerData);
                // マーカー毎の処理
                // eslint-disable-next-line max-len
                vm.markerLatLng = new window.google.maps.LatLng({ lat: dataLat, lng: dataLng }); // 緯度経度のデータ作成
                vm.marker = new window.google.maps.Marker({ // マーカーの追加
                  position: vm.markerLatLng, // マーカーを立てる位置を指定
                  map: vm.map, // マーカーを立てる地図を指定
                  disableDefaultUI: true, // デフォルトのUIを無効に
                  icon: markerImage, // import
                });
                // クリックしたら遷移するイベント
                window.google.maps.event.addListener(vm.marker, 'click', () => {
                  vm.markerLat = dataLat;
                  vm.markerLng = dataLng;
                  console.log('markerLat=', vm.markerLat);
                  console.log('markerLng=', vm.markerLng);
                  // カスタムイベントを発行
                  vm.$emit('from-child', vm.markerLat, vm.markerLng);
                  // パラメータ渡す
                  vm.$router.push({
                    name: 'detailPage',
                    params: {
                        id: `${vm.markerLat}${vm.markerLng}`
                    }
                  });
                });
              } 
            }
          }
        });
      };
      const fail = (error) => {
        vm.mapCanvasShow = false;
        const errorMessage = {
          0: '原因不明のエラーが発生しました。',
          1: '位置情報が許可されませんでした。',
          2: '位置情報が取得できませんでした。',
          3: 'タイムアウトしました。',
        };
        alert(errorMessage[error.code]);
      };
      window.navigator.geolocation.getCurrentPosition(success, fail);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*検索*/
#searchmapArea {
  background-color: #fafafa;
  padding: 5rem 0;
  text-align: center;
}

.search-title {
  margin: 0 0 1rem;
  font-family: sans-serif;
  font-size: 2.1rem;
  font-weight: 800;
  letter-spacing: 1.1rem;
}

@media (max-width: 670px) {
  .search-title {
    font-size: 1.6rem;
  }
}

.search-map__form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 1rem;
}

.search-map__form button{
  width: 12%;
  height: 5rem;
  border-radius: 2.2rem;
  box-sizing: border-box;
  color: #40210f;
  background-color: #81bf24;
  border: solid 0.3rem rgba(64, 33, 15, 0.7);
  margin-left: 0.3rem;
}

@media (max-width: 670px) {
  .search-map__form button{
    width: 20%;
    height: 4rem;
    border: solid 0.2rem #40210f;
  }
}


.search-map__formcontrol {
  font-family: inherit;
  background-color: #fafafa;
  background-image: none;
  border: solid 0.3rem rgba(64, 33, 15, 0.7);
  border-radius: 2.2rem;
  color: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 60%;
  height: 5rem;
  box-sizing: border-box;
}

@media (max-width: 670px) {
  .search-map__formcontrol {
    font-size: 0.8rem;
    height: 4rem;
    border: solid 0.2rem #40210f
  }
}

#search-currentlocation {
  cursor: pointer;
  margin-top: 0;
  background-color: #81bf24;
  border: solid 0.3rem rgba(64, 33, 15, 0.7);
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
}

@media (max-width: 670px) {
  #search-currentlocation {
    padding: 0.5rem 2rem;
    border: solid 0.2rem #40210f;
  }
}

.map-area {
  margin: 4rem auto;
}

#mapCanvas {
 margin: 0 auto 1rem;
 width: 90%;
 height: 40rem;
 border-radius: 3rem;
 border: dashed .2rem #40210f;
}

.marker-description {
  display: flex;
  justify-content: center;
  align-items: center;
}



.marker-description p {
  margin-left: 1rem;
}

@media (max-width: 670px) {
  .marker-description img{
    width: 3rem;
    height: 3rem;
  }
}

</style>
