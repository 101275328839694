<template>
  <!--トップページ-->
  <section id="headerTop">
    <!--ヘッダー-->
    <div id="headercontent" class="container">
      <a href="index.html"><img src="/img/header-title.png" alt="世界のトイレマップ" class="header-title"></a>
      <div class="loginbuttons">
        <div
        class="btn-basic loginbuttons__login"
        v-on:click="openLoginModal"
        >
          ログイン
        </div>
        <div
        class="btn-basic loginbuttons__signup"
        v-on:click="openSignupModal"
        >新規登録
        </div>
      </div>
      <p class="notice-text">※トイレ情報を投稿するにはログインが必要です</p>
    </div>
    <!--ログインモーダル-->
    <login-modal
    v-show="loginModalShow"
    v-on:click-close="closeLoginModal"
    v-on:click-resetpass="openResetpassModal"
    >
    </login-modal>
    <signup-modal
    v-show="signupModalShow"
    v-on:click-close="closeSignupModal"
    v-on:set-uname="setUserName"
    >
    </signup-modal>
    <reset-pass-modal
    v-show="resetpassModalShow"
    v-on:click-close="closeResetpassModal"
    >
      
    </reset-pass-modal>
  </section>
</template>

<script>
import LoginModal from '@/components/LoginModal.vue';
import SignupModal from '@/components/SignupModal.vue';
import ResetPassModal from '@/components/ResetPassModal.vue';
import firebase from 'firebase';

export default {
  components: {
    LoginModal,
    SignupModal,
    ResetPassModal,
  },
  data() {
    return {
      username: '',
      // 表示関連
      loginModalShow: false,
      signupModalShow: false,
      mapCanvasShow: false,
      resetpassModalShow: false,
      // currentUname: '',
      // 地図
      spot: '',
      map: {},
      marker: null,
      geocoder: {},
      lat: {},
      lng: {},
      latlng: {},
      markerlng: {},
      markerlat: {},
      markerData: {},
    };
  },
  methods: {
    openLoginModal() {
      this.loginModalShow = true;
    },
    closeLoginModal() {
      this.loginModalShow = false;
    },
    openSignupModal() {
      this.signupModalShow = true;
    },
    closeSignupModal() {
      this.signupModalShow = false;
    },
    openResetpassModal() {
      this.loginModalShow = false;
      this.resetpassModalShow = true;
    },
    closeResetpassModal() {
      this.resetpassModalShow = false;
    },
    setUserName(username) {
      console.log(username);
      this.$emit('set-uname', username);
      console.log(username);
      console.log('topに受け渡し');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#headerTop {
  background-image: url(/img/bg-img.png);
  background-color: #dcf0f7;
  background-size: contain;
  background-repeat: repeat;
  /*height: 100vh;*/
}
/*ヘッダー*/
#headercontent {
  text-align: center;
  padding-top: 4rem;
}

@media (max-width: 670px) {
  #headercontent {
    padding-top: 3rem;
  }
}

.header-title {
  width: 40%;
}

@media (max-width: 670px) {
  .header-title {
    width: 64%;
  }
}

/*ログイン・新規登録ボタン*/
#headercontent .loginbuttons {
  margin: 4rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 670px) {
  #headercontent .loginbuttons {
    margin: 3rem auto 0;
  }
}

.notice-text {
  padding: 2rem 0 5rem;
  font-weight: 500;
  font-size: 1.2rem;
}

@media (max-width: 670px) {
  .notice-text {
    padding: 1rem 0 5rem;
    font-weight: 500;
  }
}


#headercontent .loginbuttons .btn-basic {
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
  border-radius: 2.5rem;
  background-color: #75ccf3;
  border: solid 3.5px #40210f;
  color: #40210f;
  width: 23%;
  font-weight: bold;
  font-size: 1.6rem;
  transition: .3s;
  cursor: default;
  margin: 0 2rem;
}

@media (max-width: 670px) {
  #headercontent .loginbuttons .btn-basic {
    width: 30%;
    margin: 0 10px;
    font-size: 1.4rem;
    padding: 0.8rem 1rem;
    border: solid 0.2rem #40210f;
  }
}

#headercontent .loginbuttons .btn-basic:hover {
  background-color: #81bf24;
}

</style>
