<template>
  <!--トップページ-->
 <section id="usertoppage">
    <!--ユーザートップヘッダー-->
    <user-header
    v-if="userHeader"
    v-on:set-latlng="setLatlng"
    v-on:change-window="openDetail"
    v-bind:currentUname="currentUname"
    >
    </user-header>
    <!--マップ検索-->
    <search-map
     ref="searchMap"
     v-show="searchMap"
     v-on:from-child="clickMarker"
     >
    </search-map>
    <!--詳細ページ-->
    <detail-page
      v-if="detailPage"
      v-bind:registerlat="registerlat"
      v-bind:registerlng="registerlng"
      v-on:click-closebutton="closeDetail"
      v-on:no-data="deleteCloseDetail"
      >
    </detail-page>
    <Footer
    v-if="footer"
    >
    </Footer>
  </section>
</template>

<script>
import UserHeader from '@/components/UserHeader.vue';
import DetailPage from '@/components/DetailPage.vue';
import SearchMap from '@/components/SearchMap.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    UserHeader,
    DetailPage,
    SearchMap,
    Footer,
  },
  props: ['currentUname'],
  data() {
    return {
      userHeader: true,
      detailPage: false,
      searchMap: true,
      footer: true,
    };
  },
  methods: {
    setLatlng(lat, lng) {
      const registerlat = lat;
      const registerlng = lng;
      this.registerlng = registerlng;
      this.registerlat = registerlat;
      console.log('this.registerlat=', registerlat);
      console.log('this.registerlng=', registerlng);
    },
    // ユーザヘッダー閉じる
    openDetail() {
      this.userHeader = true;
      this.searchMap = false;
      this.detailPage = true;
    },
    closeDetail() {
      this.userHeader = true;
      this.searchMap = true;
      this.detailPage = false;
    },
    deleteCloseDetail() {
      // カスタムイベント発行
      this.userHeader = true;
      this.searchMap = true;
      this.detailPage = false;
      this.$refs.searchMap.closeMaparea()
    },
    clickMarker(lat, lng) {
      const registerlat = lat;
      const registerlng = lng;
      this.registerlng = registerlng;
      this.registerlat = registerlat;
      console.log('this.registerlat=', registerlat);
      console.log('this.registerlng=', registerlng);
      this.nonUserHeader = true;
      this.searchMap = false;
      this.detailPage = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*ユーザートップ*/
  
#usertoppage {
  height: 100vh;
}

</style>
