<template>
    <div class="modal-wrapping" id="modal-signup">
      <div class="overlay">
        <form class="modal-wrapping" v-on:submit.prevent="signUp(signupEmail,signupPassword)">
            <div class="close-modal" v-on:click="clickEvent"><i class="fa fa-2x fa-times"></i></div>
            <p class="modal-wrapping__title">新規登録</p>
            <div class="modal-wrapping__name">ニックネーム(8文字以内）</div>
            <input
            type="text"
            class="modal-wrapping__formcontrol login-nickname"
            v-model="signupNickname"
            v-on:keyup="checkNickname"
            >
            <div class="modal-wrapping__name">メールアドレス</div>
            <input
            type="e-mail"
            class="modal-wrapping__formcontrol login-email"
            v-model="signupEmail"
            v-on:keyup="checkEmail"
            >
            <div class="modal-msgbox">
            <p class="modal-errormsg" v-if="errormsgInvalid">無効なメールアドレスです</p>
            </div>
            <div class="modal-wrapping__name">パスワード</div>
            <input
            type="password"
            class="modal-wrapping__formcontrol login-password"
            placeholder="6文字以上"
            v-model="signupPassword"
            v-on:keyup="checkPassword"
            >
            <div class="modal-msgbox">
            <p class="modal-errormsg" v-if="errormsgPass">パスワードは6文字以上にしてください</p>
            <p class="modal-errormsg" v-else-if="errormsgDuplicate">既に登録されているメールアドレスです</p>
            </div>
            <button
            type="submit"
            class="modal-wrapping__submit"
            v-bind:disabled=addDisabled
            v-on:click="signUpfromChild"
            >
              新規登録
            </button>
        </form>
      </div>
    </div>
</template>

<script>
import v8n from 'v8n';
import firebase from 'firebase/app';

export default {
  data() {
    return {
      signupNickname: '',
      signupEmail: '',
      signupPassword: '',
      errormsgPass: false,
      errormsgDuplicate: false,
      errormsgInvalid: false,
      addDisabled: true,
    };
  },
  methods: {
  // 閉じるボタンイベント受け渡し
    clickEvent() {
      this.$emit('click-close');
    },

    // バリデーション
    checkNickname() {
      this.toggledisabled();
    },
    checkEmail() {
      const check = v8n()
        .not.null()
        .string() // 文字列
        .minLength(3) // a@b.c を想定して最低5文字
        .pattern(/[^\s@]+@[^\s@]+\.[^\s@]+/) // eメール用の正規表現
        .test(this.signupEmail); // 検証
      console.log(check);
      if (!check) {
        this.errormsgInvalid = true;
      } else {
        this.errormsgInvalid = false;
      }
      this.toggledisabled();
    },
    checkPassword() {
      const check = v8n()
        .not.null()
        .string() // 文字列
        .minLength(6)
        .test(this.signupPassword);// 検証
      console.log(check);
      if (!check) {
        this.errormsgPass = true;
      } else {
        this.errormsgPass = false;
      }
      this.toggledisabled();
    },
    toggledisabled() {
      if (this.signupNickname.length !== 0
      && this.signupEmail.length !== 0
      && this.signupPassword.length !== 0) {
        if (!this.errormsgInvalid && !this.errormsgPass) {
          this.addDisabled = false;
        }
      }
      if (this.signupNickname.length === 0
      || this.signupEmail.length === 0
      || this.signupPassword.length === 0
      || this.errormsgInvalid
      || this.errormsgPass) {
        this.addDisabled = true;
      }
    },
    // 新規登録
    signUp(email, pass) {
      firebase.auth()
        .createUserWithEmailAndPassword(email, pass)
        .then((result) => {
          console.log('登録しました', result);
          result.user.updateProfile({
            displayName: this.signupNickname,
          });
        })
        .catch((error) => {
          console.log(error.code);
          if (error.code === 'auth/email-already-in-use') {
            this.errormsgDuplicate = true;
          }
        });
    },
    // inputに入力した値を使えるように（firebaseのデータ登録が遅いので）
    signUpfromChild() {
    // $emit('親コンポーネントで発火させる関数の名前', 処理の内容);
      const username = this.signupNickname ;
      this.$emit('set-uname', username);
      console.log(username)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay .modal-wrapping {
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  margin: auto;
  width: 450px;
  background-color: #fafafa;
  border-radius: .5rem;
  text-align: center;
  padding: 2rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping {
    width: 90%;
    top: 5%;
    padding: 1rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__title {
  font-weight: 700;
  margin-bottom: 4.3rem;
  font-size: 2rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__title {
    font-weight: 700;
    font-size: 1.7rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__name {
  font-size: 1.5rem;
  line-height: 1.2rem;
  color: #40210f;
  text-align: left;
  letter-spacing: 0.2rem;
}

.overlay .modal-wrapping .modal-wrapping__formcontrol {
  /* デフォルトスタイルをリセット */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* font-familyを継承させる */
  font-family: inherit;
  background-color: transparent;
  background-image: none;
  border: 1px solid rgba(64, 33, 15, 0.7);
  border-radius: .4rem;
  color: inherit;
  font-size: 1em;
  padding: 0.4em 0.8em;
  width: 100%;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: .4rem 0 1rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__formcontrol {
    font-size: 0.8rem;
    height: 3rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__submit {
  border-radius: 2.5rem;
  background-color: #75ccf3;
  border: solid 2px rgba(64, 33, 15, 0.8);
  color: #40210f;
  width: 100%;
  font-weight: bold;
  font-size: 1.6rem;
  transition: .3s;
  cursor: default;
  margin: 4rem auto 2rem;
  line-height: 3.8rem;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .modal-wrapping__submit {
    line-height: 3.1rem;
  }
}

.overlay .modal-wrapping .modal-wrapping__submit:hover {
  background-color: #94d8f7;
}

.overlay .modal-wrapping .modal-wrapping__submit:disabled {
  color: rgba(64, 33, 15, 0.5);
  background-color: #bce3f5;
  border: solid 2px rgba(64, 33, 15, 0.5);
}

.overlay .modal-wrapping .close-modal {
  text-align: right;
  font-size: 2rem;
  cursor: pointer;
  color: #5c2f15;
}

@media (max-width: 670px) {
  .overlay .modal-wrapping .close-modal {
    font-size: 1.6rem;
  }
}

.overlay .modal-wrapping .modal-msgbox {
  height: 2rem;
  margin-bottom: 1rem;
}

.overlay .modal-wrapping .modal-msgbox .modal-errormsg {
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.1rem;
  color: red;
}

</style>
