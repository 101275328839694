<template>
  <!--トップページ-->
  <section id="toppage">
    <!--ヘッダー-->
    <non-user-header
    v-if="nonUserHeader"
    v-on:set-uname="setUserName"
    >
    </non-user-header>
    <!--マップ検索-->
    <search-map
     v-show="searchMap"
     v-on:from-child="clickMarker"
     >
    </search-map>
    <!--詳細ページ-->
    <detail-page
    v-if="detailPage"
    v-bind:registerlat="registerlat"
    v-bind:registerlng="registerlng"
    v-on:click-closebutton="closeDetailPage"
    >
    </detail-page>
    <!--<router-view></router-view>-->
    <!--フッター-->
    <Footer
    v-if="footer"
    >
    </Footer>
  </section>
</template>

<script>
import NonUserHeader from '@/components/NonUserHeader.vue';
import DetailPage from '@/components/DetailPage.vue';
import SearchMap from '@/components/SearchMap.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    NonUserHeader,
    DetailPage,
    SearchMap,
    Footer,
  },
  data() {
    return {
      usename: '',
      // 表示関連
      nonUserHeader: true,
      detailPage: false,
      searchMap: true,
   　  footer: true,
    };
  },
  methods: {
    closeDetailPage() {
      this.detailPage = false;
      this.nonUserHeader = true;
      this.searchMap = true;
    },
    setUserName(username) {
      console.log(username);
      this.$emit('set-uname', username);
    },
    clickMarker(lat, lng) {
      const registerlat = lat;
      const registerlng = lng;
      this.registerlng = registerlng;
      this.registerlat = registerlat;
      console.log('this.registerlat=', registerlat);
      console.log('this.registerlng=', registerlng);
      this.nonUserHeader = true;
      this.searchMap = false;
      this.detailPage = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
