<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import {RouterView} from 'vue-router' ;　いらない


export default {
  components: {
    // RouterViewいらない
  },
  // data() {
  //   return {
  //     // 表示関連
  //     nonuser: true,
  //     user: false,
  //     currentUid: '',
  //     currentUname: '',
  //   };
  // },
  // mounted() {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       console.log('login', user);
  //       this.currentUid = user.uid;
  //       this.nonuser = false;
  //       this.user = true;
  //       // 通常のログイン時
  //       console.log('user.providerData', user.providerData[0].displayName);
  //       if (user.displayName !== null) {
  //         this.currentUname = user.displayName;
  //       }
  //       console.log(this.currentUname);
  //       // // 全てのデータ
  //     } else {
  //       console.log('logout');
  //       this.nonuser = true;
  //       this.user = false;
  //     }
  //   });
  // },
  // methods: {
  //   setUserName(username) {
  //     this.currentUname = username;
  //   },
  //   isResetPage () {
  //     return this.$route.path === '/reset';
  //   }
  // },
};
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/

html {
  font-size: 62.5%;
  font-family: "游ゴシック",
      "Yu Mincho",
      "YuGothic",
      "Roboto",
      "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro",
      "メイリオ",
      Meiryo, Osaka,
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      sans-serif;
  background-color: #fafafa;
}

.pc {
  display: block;
}

@media (max-width: 670px) {
  .pc {
    display: none;
  }
}

.sp {
  display: none;
}

@media (max-width: 670px) {
  .sp {
    display: block;
  }
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #40210f;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: .15rem;
  /*margin: 0 auto;*/
}

@media (max-width: 670px) {
  body {
    font-size: 1.4rem;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

@media (max-width: 670px) {
  .container {
     min-width: 100%;
  }
}
</style>
