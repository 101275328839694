import Vue from 'vue';
import firebase from 'firebase';
import router from './route.js' 
import App from './App.vue';

Vue.config.productionTip = false;

// firebase
const firebaseConfig = {
  apiKey: 'AIzaSyC767zSPXlsRPkT5pLZmQP87rn6P3QSYGE',
  authDomain: 'restroommap-72689.firebaseapp.com',
  projectId: 'restroommap-72689',
  storageBucket: 'restroommap-72689.appspot.com',
  messagingSenderId: '853499226962',
  appId: '1:853499226962:web:0a248cf8e2598b645be88b',
};
// No Firebase App '[DEFAULT]' has been creatededエラー初期化
firebase.initializeApp(firebaseConfig);


new Vue({
  router,  // インスタンスを追加
  render: (h) => h(App),
}).$mount('#app');
