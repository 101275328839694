<template>
  <!-- eslint-disable max-len -->
  <div>
  <!--詳細ページ-->
    <section class="detail-page container">
      <div class="close-window" v-on:click="clickClose"><i class="fa fa-2x fa-times"></i></div>
        <div class="detail-page__wrap">
        <div class="gallary-map__wrap">
          <!--ギャラリー-->
          <div id="detailGallary">
            <!--<h1 class="detail-page__title gallary"><span>G</span>ALLARY</h1>-->
            <img src="/img/gallary-title.png" class="detail-page__title gallary">
              <carousel
              id="gallaryItem"
              v-bind:per-page="1"
              v-bind:autoplay="true"
              v-bind:centerMode="true"
              v-bind:loop="true"
              v-bind:NavigationEnabled="false"
              paginationActiveColor="#81bf24"
              v-bind:speed="1000"
              v-bind:paginationPadding="3">
                <slide
                class="carousel-list"
                v-for="(GallaryImg ,index ) in gallaryImgs"
                v-bind:key="index.gallaryImg"
                >
                  <img class="carousel-img"
                  v-if="GallaryImg !== null"
                  v-bind:src="GallaryImg"
                  alt="トイレの画像">
                  <img
                  v-else
                  src="/img/noimage.png"
                  alt="画像なし">
                </slide>
              </carousel>
          </div>
          <!--マップ-->
          <div id="detailMap">
            <!--<h1 class="detail-page__title map"><span>M</span>AP</h1>-->
            <img src="/img/map-title.png" class="detail-page__title map">
            <div id="mapArea"></div>
            <a target="_blank" v-bind:href="`https://maps.google.com/maps?q=${registerlat},${registerlng}`" class="contentarea_a-small">GoogleMapアプリで見る</a>
            <!--<a target="_blank" v-bind:href="`https://www.google.com/maps/search/?api=1&query=${Lat},${Lng}`" class="contentarea_a-small">GoogleMapアプリで見る</a>-->
          </div>
        </div>
        <!--レビュー-->
        <div id="detailReview">
          <!--<h1 class="detail-page__title review"><span>R</span>EVIEW</h1>-->
          <img src="/img/review-title.png" class="detail-page__title review">
          <div class="totalrating-contents">
            <p class="totalrate-title">総合評価</p>
            <div class="totalrate cleanliness">
                <p>清潔度</p>
                <star-rating
                read-only
                v-bind:rating="clianlinessAverage"
                v-bind:increment="0.5"
                inactive-color="#e8e6e6"
                active-color="#81bf24"
                v-bind:star-size="30"
                v-bind:star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                v-bind:rounded-corners="true"
                ></star-rating>
            </div>
            <div class="totalrate rare">
                <p>レア度</p>
                <star-rating
                read-only
                v-bind:rating="rareAverage"
                v-bind:increment="0.5"
                inactive-color="#e8e6e6"
                active-color="#81bf24"
                v-bind:star-size="30"
                v-bind:star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                v-bind:rounded-corners="true"
                ></star-rating>
            </div>
          </div>
          <div
          class="add-review__button"
          v-on:click=openReviewModal
          v-if="currentUid !== null && registerUids.indexOf(currentUid) === -1"
          >
            レビューを投稿する<br>
          <span><i class="fas fa-plus-circle"></i></span>
          </div>
          <div
          id="reviewItem"
          v-for="{ CurrentId , Paper, Pay , CleanlinessRating ,RareRating, Visitdate , NewComment ,ImageUrl ,Year , Month , Day , DayOfWeek } in reviews"
          v-bind:key="CurrentId.review"
          > 
          <div class="review-title">
              <div id="registerDate" >{{ Year }}&nbsp;{{ Month }}&nbsp;{{ Day }}&nbsp;({{DayOfWeek}})</div>
              <div class="delete-edit__buttons pc">
                <p class="delete-button" v-if="CurrentId === currentUid" v-on:click=deleteReview>削除する<i class="fas fa-trash"></i></p>
                <p class="edit-button" v-if="CurrentId === currentUid" v-on:click=openEditModal>編集する<i class="fas fa-pen"  v-if="CurrentId === currentUid"></i></p>
              </div>
              <div class="delete-edit__buttons sp">
                <i class="fas fa-trash" v-if="CurrentId === currentUid" v-on:click=deleteReview></i>
                <i class="fas fa-pen" v-if="CurrentId === currentUid" v-on:click=openEditModal></i>
              </div>
            </div>
            <div class="review-wrap">
              <div class=review-upper>
                <div class="review-info">
                  <p class="VisitDate" v-if="reviews && Visitdate.length !== 0">訪れた日：{{ Visitdate }}</p>
                  <p class="paper">トイレットペーパー：{{ Paper }}</p>
                  <p class="pay">使用料：{{ Pay }}</p>
                  <div class="rate cleanliness">
                      <p>清潔度</p>
                      <star-rating
                      read-only
                      v-bind:rating="CleanlinessRating"
                      v-bind:increment="0.5"
                      inactive-color="#e8e6e6"
                      active-color="#81bf24"
                      v-bind:star-size="30"
                      v-bind:star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                      v-bind:rounded-corners="true"
                      ></star-rating>
                  </div>
                  <div class="rate cleanliness">
                      <p>レア度</p>
                      <star-rating
                      read-only
                      v-bind:rating="RareRating"
                      v-bind:increment="0.5"
                      inactive-color="#e8e6e6"
                      active-color="#81bf24"
                      v-bind:star-size="30"
                      v-bind:star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                      v-bind:rounded-corners="true"
                      ></star-rating>
                  </div>
                </div>
               <!--<img src="https://firebasestorage.googleapis.com/v0/b/restroommap-72689.appspot.com/o/restroom-images%2Fitem_book_5.jpg?alt=media&token=37c4e47f-7a70-4fe0-b805-088484798399"/>-->
               <img class="review-img" v-bind:src="ImageUrl" v-if="ImageUrl !== null"/>
             </div>
             <div class=review-lower v-if="reviews && NewComment.length !== 0">
               <p class="comment">{{ NewComment }}</p>
             </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--編集モーダル-->
    <edit-modal
    v-show="editModalShow"
    v-bind:lat = "registerlat"
    v-bind:lng = "registerlng"
    v-on:click-closebutton="closeEditModal"
    v-on:click-editbutton="closeEditModal"
    >
    </edit-modal>
    <review-modal
    v-show="reviewModalShow"
    v-bind:lat = "registerlat"
    v-bind:lng = "registerlng"
    v-on:click-closebutton="closeReviewModal"
    v-on:click-editbutton="closeReviewModal"
    >
    </review-modal>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import { Carousel, Slide } from 'vue-carousel';
import StarRating from 'vue-star-rating';
import markerImage from '@/assets/marker-icon.png';
import EditModal from '@/components/EditModal.vue';
import ReviewModal from '@/components/ReviewModal.vue';

export default {
  props: ['registerlat', 'registerlng'],
  components: {
    Carousel,
    Slide,
    StarRating,
    EditModal,
    ReviewModal,
  },
  data() {
    return {
      // 表示関連
      editModalShow: false,
      reviewModalShow: false,
      map: {},
      marker: null,
      reviews: [],
      gallaryImgs: [],
      registerUids: [],
      currentUid: null,
      rareAverage: 0,
      clianlinessAverage: 0,
      // CurrentId: [],
      addReviewButton: false,
    };
  },
  async mounted() {
    // document.getElementByClassName('VueCarousel-dot').removeAttribute('style');
    // データ取得
    firebase
      .firestore()
      .collection(`${this.registerlat}${this.registerlng}`)
      .orderBy('CreateAt')
      .onSnapshot((snap) => {
        const array = [];
        const imgArray = [];
        const idArray = [];
        const clianlinessArray =[];
        const rareArray =[];
        snap.forEach((doc) => {
          // Error`Cannot read property 'toDate()' of null` ?記述
          // コメントを配列に加える
          const dateObj = doc.data();
          this.newComment = dateObj.Comment.replaceAll('\\n', '\n');
          dateObj.NewComment = `${this.newComment}`;
          // 投稿日を配列に加える
          const createdAtDate = dateObj.CreateAt?.toDate();
          const dateToString = createdAtDate?.toString();
          this.dayOfWeek = dateToString?.slice( 0, 3 );
          this.month = dateToString?.slice( 4, 7 );
          this.day = dateToString?.slice( 8, 10 );
          this.year = dateToString?.slice( 11, 15 );
          dateObj.DayOfWeek = `${this.dayOfWeek}`;
          dateObj.Month = `${this.month}`;
          dateObj.Day = `${this.day}`;
          dateObj.Year = `${this.year}`;
          array.push(dateObj);
          // IDの配列をつくる
          idArray.push(dateObj.CurrentId);
          // ギャラリー画像の配列をつくる
          imgArray.push(dateObj.ImageUrl);
          // console.log("imgArray",imgArray);
          if (imgArray.length !==1) {
            let i =+ 1;
            // 画像なし前の画像あり
            if( imgArray[i] === null && imgArray[i-1] !== null) {
              imgArray.splice([i], 1);
              console.log("画像なし前の画像あり");
            }　
            // 画像なしorあり前の画像なし
            if(( imgArray[i] === null || imgArray[i] !== null) && imgArray[i-1] === null) {
              imgArray.splice([i-1], 1);
              console.log("画像なしorあり前の画像なし");
            }
          }
          // 星評価の配列をつくる
          const Cleanlinessdata = dateObj.CleanlinessRating;
          const cleanlinessRating = Number(Cleanlinessdata);
          clianlinessArray.push( cleanlinessRating);
          const raredata = dateObj.RareRating;
          const rareRating = Number(raredata);
          rareArray.push(rareRating);
          console.log('データ取得完了');
        }, snap);
        this.reviews = array;
        this.gallaryImgs =imgArray;
        this.registerUids =idArray;
        const clianlinessSum = clianlinessArray.reduce((sum, element) => sum + element, 0);
        const rareSum = rareArray.reduce((sum, element) => sum + element, 0);
        this.clianlinessAverage = clianlinessSum / clianlinessArray.length
        this.rareAverage = rareSum / clianlinessArray.length
        console.log("rareAverage",this.rareAverage);
        console.log("clianlinessAverage",this.clianlinessAverage);
        console.log(this.registerUids.length);
      });
    // user id取得
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.currentUid = user.uid;
        console.log(this.currentUid);
      }
    });
    await this.waitGmap();
    // 地図表示
    this.map = new window.google.maps.Map(document.getElementById('mapArea'), {
      center: { lat: this.registerlat, lng: this.registerlng },
      zoom: 12,
    });
    this.marker = new window.google.maps.Marker({
      position: { lat: this.registerlat, lng: this.registerlng },
      map: this.map,
      icon: markerImage, // import
    });
  },
  methods: {
    // uIdSeach() {
    //   const result = this.registerUids.indexOf(`${this.currentUid}`);
    //   console.log(result);
    // },
    // DetailPagecloseボタンイベントハンドラ発行
    clickClose() {
      this.$emit('click-closebutton');
      console.log('クリックイベント');
    },
    // editModal開く
    openEditModal () {
      this.editModalShow= true;
    },
    // editModal閉じる
    closeEditModal() {
      this.editModalShow= false;
    },
    // reviewModal開く
    openReviewModal () {
      this.reviewModalShow= true;
    },
    // editModal閉じる
    closeReviewModal() {
      this.reviewModalShow= false;
    },
    // 読み込み
    waitGmap() {
      return new Promise((resolve, reject) => {
        let retry = 0;
        const id = setInterval(() => {
          if (window.gmaploaded === true) {
            resolve();
            clearInterval(id);
          } else {
            retry += 1;
            if (retry === 10) {
              reject();
              clearInterval(id);
            }
          }
        }, 100);
      });
    },
   // データを削除
   deleteReview() {
     if (confirm('レビューを削除しますか？')) {
      console.log('Ok')
      firebase
        .firestore()
        .collection(`${this.registerlat}${this.registerlng}`)
        .doc(`${this.currentUid}`)
        .delete()
        .then(() => {
          console.log('Document successfully deleted!');
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });
     　firebase
        .firestore()
        .collection('restroomMap')
        .doc(`${this.registerlat}${this.registerlng}`)
        .get()
        .then((doc) => {
          console.log(doc.data().CurrentId.length);
          // データが1件以上のとき
          if (doc.data().CurrentId.length !== 1) {
            firebase
              .firestore()
              .collection('restroomMap')
              .doc(`${this.registerlat}${this.registerlng}`)
              .update({
                CurrentId: firebase.firestore.FieldValue.arrayRemove(`${this.currentUid}`),
           });
          } else {
            firebase
              .firestore()
              .collection('restroomMap')
              .doc(`${this.registerlat}${this.registerlng}`)
              .delete()
              .then(() => {
                console.log('Document successfully deleted!');
                // カスタムイベント発行
                this.$emit('no-data');
              }).catch((error) => {
                console.error('Error removing document: ', error);
              });
          } 
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    } else {
      console.log('キャンセル')
      }
　  },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.detail-page {
  background: #fafafa;
  padding: 5rem;
}

@media (max-width: 670px) {
  .detail-page {
    padding: 1rem;
 }
}

.detail-page__wrap {
  border: double 3.8rem #dcf0f7;
  border-radius: 15rem;
  padding: 5rem 2rem 9rem
}

@media (max-width: 670px) {
  .detail-page__wrap {
    border: double 1.8rem #dcf0f7;
    border-radius: 3rem;
    padding: 0 2rem 6rem;
  }
}

/*閉じるボタン*/
.close-window {
  font-size: 2.5rem;
  text-align: end;
  margin-bottom: 2rem;
}

@media (max-width: 670px) {
  .close-window {
    font-size: 1.3rem;
    margin: 1rem;
  }
}

/*タイトル*/
.detail-page__title {
  margin: 6rem auto 1rem;
}

@media (max-width: 670px) {
  .detail-page__title {
    margin: 7rem auto 3rem;
  }
}

.detail-page__title.map {
  width: 52%;
}

@media (max-width: 670px) {
  .detail-page__title.map {
    width: 80%;
 }
}

.detail-page__title.gallary {
 width: 65.5%;
}

@media (max-width: 670px) {
  .detail-page__title.gallary {
    width: 80%;
 }
}

.detail-page__title.review {
    width: 29%;
}

@media (max-width: 670px) {
  .detail-page__title.review {
    width: 80%;
 }
}

.gallary-map__wrap {
  display: flex;
}

@media (max-width: 670px) {
  .gallary-map__wrap {
    display: flex;
    flex-direction: column;
  }
}

/*ギャラりー*/
#detailGallary {
  width: 40%;
  text-align: center;
  margin: 0 auto 6rem;
}

@media (max-width: 670px) {
  #detailGallary {
    width: 100%;
    margin: 0 auto 3rem;
  }
}

.carousel-img {
  height: 25rem;
}

.VueCarousel-dot-container  button {
  margin: 0;
}

.VueCarousel-dot {
  margin: 0;
}

/*マップ*/
#detailMap {
  width: 50%;
  text-align: center;
  margin: 0 auto;
  margin: 0 auto 6rem;
}

@media (max-width: 670px) {
  #detailMap {
    width: 100%;
    margin: 0 auto 3rem;
  }
}

#mapArea {
  width: 90%;
  height: 25rem;
  margin: 0 auto 1rem;
  border: solid 0.15rem rgb(87, 79, 75, 1);
  border-radius: 2.3rem
}

@media (max-width: 670px) {
  #mapArea {
    height: 35rem;
    margin: 0 auto 1rem;
    border: solid 0.1rem rgb(87, 79, 75, 1);
    border-radius: 1.3rem;
    width: 100%;
  }
}

.contentarea_a-small {
  color: #40210f;
  text-decoration: none;
}

/*レビュー*/
#detailReview {
  width: 90%;
  text-align: center;
  margin: 0 auto 9rem;
}

@media (max-width: 670px) {
  #detailReview {
    margin: 0 auto 3rem;
    width: 100%;
  }
}

.review-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
}

.add-review__button {
  padding: 0.6rem 1.7rem;
  margin: 3rem auto 2.5rem;
  border-radius: 10.5rem;
  background-color: #81bf24;
  border: solid 0.15rem #40210f;
  color: #40210f;
  width: 18rem;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 2.2rem;
  transition: .3s;
  cursor: pointer;
}

@media (max-width: 670px) {
  .add-review__button {
   margin: 0 auto 5rem;
   width: 15rem;
   padding: 0.4rem 1.7rem;
  }
}

.add-review__button span {
  font-size: 2rem;
}

@media (max-width: 670px) {
  .add-review__button span {
    font-size: 1.7rem;
  }
}

.delete-edit__buttons.sp {
  display:none;
}

@media (max-width: 670px) {
  .delete-edit__buttons.sp {
  display: flex;
  justify-content: end;
  cursor: pointer;
  }
}

.delete-edit__buttons.pc {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

@media (max-width: 670px) {
  .delete-edit__buttons.pc {
  display: none;
  }
}

.delete-edit__buttons i {
  font-size: 2.5rem;
  margin-left: 0.6rem;
}

@media (max-width: 670px) {
  .delete-edit__buttons i {
    font-size: 2.5rem;
    margin: 0 1.1rem 0 0;
  }
}

.delete-edit__buttons p {
  margin: 0 2.5rem 0 0;
}

.delete-button:hover {
  color: #8c7e77;
}

.edit-button:hover {
  color: #8c7e77;
}

#registerDate {
  color: #8c7e77;
  margin-left: 2.5rem;
}

@media (max-width: 670px) {
  #registerDate {
    margin-left: 1rem;
  }
}

.review-wrap {
  border: solid 0.15rem rgb(87, 79, 75, 1);
  border-radius: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 4rem;
  padding: 2rem;
  border-radius: 2.3rem;
}

@media (max-width: 670px)  {
  .review-wrap {
    border: solid 0.1rem rgb(87, 79, 75, 1);
    border-radius: 1.3rem;
  }
}

.totalrating-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto 3rem;
}

@media (max-width: 670px) {
  .totalrating-contents {
    margin: 0 auto 2rem;
  }
}

.totalrate-title {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: .3rem;
  margin-top: 0;
}

.totalrate {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.totalrate p {
  margin: 0 0.2rem 0 0;
}

.review-img {
  height: 16rem;
}

.review-upper {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media (max-width: 670px) {
  .review-upper {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
  }
}

.review-lower {
    text-align: center;
    border-top: dotted 0.1rem rgb(64, 33, 15, 0.7);
}

.comment {
  white-space: pre-wrap;
}

.rate {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.rate p {
  margin: 0;
}

@media (max-width: 670px) {
  .rate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}



  </style>
